/* eslint-disable @typescript-eslint/no-explicit-any */

/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { IconWeight } from "@phosphor-icons/react";
import * as _ from "lodash-es";
import dynamic from "next/dynamic";
import { forwardRef, useRef } from "react";

import { BreakpointOrBreakpointObject } from "@/types/tokens/breakpoints";

import { BorderRadiuses } from "@/tokens/border";
import { Color, Colors } from "@/tokens/color";
import { AllIcons } from "@/tokens/configs/icon_config";
import { FontStyleSlug } from "@/tokens/configs/typography_config";

import LogoIcon from "@/ui/atoms/logo_icon";

import { useCombinedRefs } from "@/util/hooks/ref_hooks";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { getIconSizeStyles, getIconStylesForFontSize } from "@/util/icon_util";

interface IconProps {
    className?: SerializedStyles;
    color?: Color;
    fontSize?: FontStyleSlug;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    renderContainer?: boolean;
    size?: BreakpointOrBreakpointObject<number>;
    slug: AllIcons;
    weight?: IconWeight;
}

// Create a map of dynamically imported components with SSR enabled
const DynamicIcons = {
    "Agent Mode": dynamic<any>(
        () =>
            import("@/ui/atoms/agent_mode_icon").then(
                (mod) => mod.AgentModeIcon,
            ),
        {},
    ),
    "Arrow Down": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/ArrowDown").then(
                (mod) => mod.ArrowDown,
            ),
        {},
    ),
    "Arrow Left": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/ArrowLeft").then(
                (mod) => mod.ArrowLeft,
            ),
        {},
    ),
    "Arrow Right": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/ArrowRight").then(
                (mod) => mod.ArrowRight,
            ),
        {},
    ),
    "Arrow Up": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/ArrowUp").then(
                (mod) => mod.ArrowUp,
            ),
        {},
    ),
    "Arrow Up Right": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/ArrowUpRight").then(
                (mod) => mod.ArrowUpRight,
            ),
        {},
    ),
    Bluesky: dynamic<any>(
        () => import("@/ui/atoms/bluesky_icon").then((mod) => mod.BlueskyIcon),
        {},
    ),
    "Caret Left": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/CaretLeft").then(
                (mod) => mod.CaretLeft,
            ),
        {},
    ),
    "Caret Right": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/CaretRight").then(
                (mod) => mod.CaretRight,
            ),
        {},
    ),
    "Chat Bubbles": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/ChatsCircle").then(
                (mod) => mod.ChatsCircle,
            ),
        {},
    ),
    Check: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Check").then(
                (mod) => mod.Check,
            ),
        {},
    ),
    "Check Circled": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/CheckCircle").then(
                (mod) => mod.CheckCircle,
            ),
        {},
    ),
    "Circuit Board": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Circuitry").then(
                (mod) => mod.Circuitry,
            ),
        {},
    ),
    Clock: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Clock").then(
                (mod) => mod.Clock,
            ),
        {},
    ),
    Close: dynamic<any>(
        () => import("@phosphor-icons/react/dist/ssr/X").then((mod) => mod.X),
        {},
    ),
    "CPU Chip": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Cpu").then((mod) => mod.Cpu),
        {},
    ),
    "Database Stack": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Database").then(
                (mod) => mod.Database,
            ),
        {},
    ),
    Detective: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Detective").then(
                (mod) => mod.Detective,
            ),
        {},
    ),
    Diamond: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/DiamondsFour").then(
                (mod) => mod.DiamondsFour,
            ),
        {},
    ),
    Discord: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/DiscordLogo").then(
                (mod) => mod.DiscordLogo,
            ),
        {},
    ),
    Download: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/DownloadSimple").then(
                (mod) => mod.DownloadSimple,
            ),
        {},
    ),
    Eye: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Eye").then((mod) => mod.Eye),
        {},
    ),
    Flag: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Flag").then(
                (mod) => mod.Flag,
            ),
        {},
    ),
    GitHub: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/GithubLogo").then(
                (mod) => mod.GithubLogo,
            ),
        {},
    ),
    Globe: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Globe").then(
                (mod) => mod.Globe,
            ),
        {},
    ),
    "Hand Waving": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/HandWaving").then(
                (mod) => mod.HandWaving,
            ),
        {},
    ),
    "Hand with Coins": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/HandCoins").then(
                (mod) => mod.HandCoins,
            ),
        {},
    ),
    "Hand with Up Arrow": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/HandArrowUp").then(
                (mod) => mod.HandArrowUp,
            ),
        {},
    ),
    Heart: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Heart").then(
                (mod) => mod.Heart,
            ),
        {},
    ),
    Highlighter: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Highlighter").then(
                (mod) => mod.Highlighter,
            ),
        {},
    ),
    Keyboard: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Keyboard").then(
                (mod) => mod.Keyboard,
            ),
        {},
    ),
    Laptop: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Laptop").then(
                (mod) => mod.Laptop,
            ),
        {},
    ),
    Layout: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Layout").then(
                (mod) => mod.Layout,
            ),
        {},
    ),
    "Lego Block": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Lego").then(
                (mod) => mod.Lego,
            ),
        {},
    ),
    Lightning: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Lightning").then(
                (mod) => mod.Lightning,
            ),
        {},
    ),
    LinkedIn: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/LinkedinLogo").then(
                (mod) => mod.LinkedinLogo,
            ),
        {},
    ),
    Linux: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/LinuxLogo").then(
                (mod) => mod.LinuxLogo,
            ),
        {},
    ),
    Lock: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Lock").then(
                (mod) => mod.Lock,
            ),
        {},
    ),
    Mac: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/AppleLogo").then(
                (mod) => mod.AppleLogo,
            ),
        {},
    ),
    "Magnifying Glass": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/MagnifyingGlass").then(
                (mod) => mod.MagnifyingGlass,
            ),
        {},
    ),
    Moon: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Moon").then(
                (mod) => mod.Moon,
            ),
        {},
    ),
    "Multiple User Avatar": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/UsersThree").then(
                (mod) => mod.UsersThree,
            ),
        {},
    ),
    "Note and Pencil": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/NotePencil").then(
                (mod) => mod.NotePencil,
            ),
        {},
    ),
    "Office Chair": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/OfficeChair").then(
                (mod) => mod.OfficeChair,
            ),
        {},
    ),
    "Open Book": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/BookOpen").then(
                (mod) => mod.BookOpen,
            ),
        {},
    ),
    "Paint Palette": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Palette").then(
                (mod) => mod.Palette,
            ),
        {},
    ),
    Play: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Play").then(
                (mod) => mod.Play,
            ),
        {},
    ),
    Plugs: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Plugs").then(
                (mod) => mod.Plugs,
            ),
        {},
    ),
    Plus: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Plus").then(
                (mod) => mod.Plus,
            ),
        {},
    ),
    "Puzzle Piece": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/PuzzlePiece").then(
                (mod) => mod.PuzzlePiece,
            ),
        {},
    ),
    Question: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Question").then(
                (mod) => mod.Question,
            ),
        {},
    ),
    Rocket: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/RocketLaunch").then(
                (mod) => mod.RocketLaunch,
            ),
        {},
    ),
    RSS: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Rss").then((mod) => mod.Rss),
        {},
    ),
    "Settings Gear": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/GearSix").then(
                (mod) => mod.GearSix,
            ),
        {},
    ),
    Sidebar: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/SidebarSimple").then(
                (mod) => mod.SidebarSimple,
            ),
        {},
    ),
    "Single User Avatar": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/User").then(
                (mod) => mod.User,
            ),
        {},
    ),
    Stack: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Stack").then(
                (mod) => mod.Stack,
            ),
        {},
    ),
    Sunset: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/SunHorizon").then(
                (mod) => mod.SunHorizon,
            ),
        {},
    ),
    "Syncing Arrows": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/ArrowsClockwise").then(
                (mod) => mod.ArrowsClockwise,
            ),
        {},
    ),
    "Terminal Window": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/TerminalWindow").then(
                (mod) => mod.TerminalWindow,
            ),
        {},
    ),
    TikTok: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/TiktokLogo").then(
                (mod) => mod.TiktokLogo,
            ),
        {},
    ),
    Twitter: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/XLogo").then(
                (mod) => mod.XLogo,
            ),
        {},
    ),
    Upload: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/CloudArrowUp").then(
                (mod) => mod.CloudArrowUp,
            ),
        {},
    ),
    Users: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Users").then(
                (mod) => mod.Users,
            ),
        {},
    ),
    "Warning Circled": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/WarningCircle").then(
                (mod) => mod.WarningCircle,
            ),
        {},
    ),
    Warp: LogoIcon,
    Waves: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/Waves").then(
                (mod) => mod.Waves,
            ),
        {},
    ),
    Windows: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/WindowsLogo").then(
                (mod) => mod.WindowsLogo,
            ),
        {},
    ),
    X: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/XLogo").then(
                (mod) => mod.XLogo,
            ),
        {},
    ),
    "X Circled": dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/XCircle").then(
                (mod) => mod.XCircle,
            ),
        {},
    ),
    YouTube: dynamic<any>(
        () =>
            import("@phosphor-icons/react/dist/ssr/YoutubeLogo").then(
                (mod) => mod.YoutubeLogo,
            ),
        {},
    ),
} as const;

export const Icon = forwardRef<HTMLElement, IconProps>(
    ({ renderContainer = false, size = 24, ...props }, ref) => {
        /**
         * Globals & Refs
         */
        const _localIconRef = useRef<HTMLAnchorElement>(null);
        const iconRef = useCombinedRefs<HTMLAnchorElement>(ref, _localIconRef);
        const theme = useTypedTheme();
        const IconComponent = DynamicIcons[props.slug];

        const iconColorAttribute =
            !!props.color && _.has(Colors, props.color)
                ? Colors[props.color]
                : "currentColor";

        /**
         * Styles
         */
        const iconContainerStyles = css(
            renderContainer &&
                css(
                    {
                        alignItems: "center",
                        backdropFilter: "blur(16px)",
                        backgroundColor:
                            Colors[theme.backgrounds.backgroundTertiary],
                        borderRadius: BorderRadiuses.borderSmall,
                        display: "flex",
                        justifyContent: "center",
                    },
                    props.fontSize &&
                        getIconStylesForFontSize(props.fontSize, "padding"),
                ),
            props.className,
        );

        const iconStyles = css(
            {
                height: "auto",
                ...(props.onClick && {
                    cursor: "pointer",
                }),
            },
            props.fontSize
                ? getIconStylesForFontSize(props.fontSize, "width")
                : getIconSizeStyles(size),
        );

        if (!IconComponent) {
            // Optional: Return a placeholder or null
            return <span css={iconContainerStyles} ref={iconRef} />;
        }

        return (
            <span
                css={iconContainerStyles}
                ref={iconRef}
                onClick={props.onClick}
            >
                <IconComponent
                    color={iconColorAttribute}
                    css={iconStyles}
                    weight={props.weight}
                />
            </span>
        );
    },
);

Icon.displayName = "Icon";
