import { OperatingSystem } from "@/types/data";
import type { StrapiDownloadGroup } from "@/types/strapi";

/**
 * Filters and modifies the DownloadGroup Primary_Action based on the detected
 * operating system and architecture.
 */
export function getPlatformDetectedPrimaryActions({
    actions,
    architecture,
    operatingSystem,
}: {
    actions: StrapiDownloadGroup["Primary_Action"];
    architecture: UADataValues["architecture"];
    operatingSystem?: OperatingSystem;
}): StrapiDownloadGroup["Primary_Action"] {
    if (operatingSystem !== "windows") {
        return actions;
    }

    const result: StrapiDownloadGroup["Primary_Action"] = [];
    for (const action of actions) {
        if (action.__component === "components.component-waitlist-form") {
            result.push(action);
            continue;
        }

        switch (architecture) {
            case "arm": {
                if (action.Architecture === "arm64") {
                    return [...result, action];
                }
                break;
            }
            case "x86": {
                if (action.Architecture === "x64") {
                    return [...result, action];
                }
                break;
            }
            default:
                return [
                    ...result,
                    {
                        ...action,
                        Secondary_Text: "x64, ARM64",
                        URL: "https://app.warp.dev/get_warp",
                    },
                ];
        }
    }
    return result as StrapiDownloadGroup["Primary_Action"];
}
