/** @jsxImportSource @emotion/react */
import { type SerializedStyles, css } from "@emotion/react";
import { type FunctionComponent, useMemo } from "react";

import type {
    StrapiDownloadGroup,
    StrapiDownloadOption,
    StrapiWaitlistForm,
} from "@/types/strapi";

import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { CodeCta } from "@/ui/atoms/code_cta";
import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { OperatingSystemIcon } from "@/ui/atoms/operating_system_icon";
import { Text } from "@/ui/atoms/text";
import { NestedForm } from "@/ui/molecules/nested_form";

import { getCodeCtaTrack } from "@/util/code_cta_util";
import { useGlobalsContext } from "@/util/context/globals_context";
import { getPlatformDetectedPrimaryActions } from "@/util/download_group_util";
import { getDownloadOptionTrack } from "@/util/download_option_util";
import { buildStylesByBreakpoint } from "@/util/style_util";
import type { RenderLocation } from "@/util/tokens/render_location_util";

interface DownloadGroupProps extends StrapiDownloadGroup {
    className?: SerializedStyles;
    isDownloadPreviewPage?: true;
    renderLocation?: RenderLocation;
}

export const DownloadGroupItem: FunctionComponent<DownloadGroupProps> = (
    props,
) => {
    /**
     * Globals
     */
    const _castedOS_Name = props.OS_Name.toLowerCase() as
        | "mac"
        | "windows"
        | "linux";

    const { architecture, operatingSystem = "mac" } = useGlobalsContext();

    const primaryActions = useMemo(() => {
        if (props.OS_Name.toLowerCase() !== "windows") {
            return props.Primary_Action;
        }

        return getPlatformDetectedPrimaryActions({
            actions: props.Primary_Action,
            architecture,
            operatingSystem,
        });
    }, [architecture, props.OS_Name, props.Primary_Action, operatingSystem]);

    /*
     * Helpers
     */
    const getIconSize = {
        linux: {
            height: 48,
            width: 39.7,
        },
        mac: {
            height: 40,
            width: 37.113,
        },
        windows: {
            height: 36,
            width: 44.47,
        },
    };

    /**
     * Styles
     */
    const downloadCardStyles = css(
        {
            display: "flex",
            flexDirection: "column",
            width: "100%",
        },

        buildStylesByBreakpoint("gap", {
            extraSmall: Spacing["spacing-6"],
            large: Spacing["spacing-8"],
        }),
        buildStylesByBreakpoint("padding", {
            medium: `${Spacing["spacing-6"]} 0`,
        }),
    );

    const downloadGroupHeaderStyles = css({
        display: "grid",
        gap: Spacing["spacing-3"],
    });

    const downloadGroupTextStyles = css({
        alignItems: "baseline",
        display: "flex",
        gap: Spacing["spacing-3"],
    });

    const primaryButtonContainerStyles = css({
        alignItems: "start",
        display: "flex",
        gap: Spacing["spacing-3"],
        width: "100%",
    });

    const primaryButtonGroupStyles = css({
        display: "flex",
        flex: "1 0 0",
        flexDirection: "column",
        rowGap: Spacing["spacing-3"],
    });

    const primaryButtonStyles = css(
        {
            display: "flex",
            justifyContent: "center",
        },
        {
            "&:is(:hover, :focus) *": {
                color: Colors["lighten-80"],
            },
        },
    );

    const primaryButtonTextStyles = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
    });

    const downloadRowStyles = css({
        alignItems: "center",
        display: "flex",
        gap: Spacing["spacing-3"],
        justifyContent: "space-between",
    });

    const downloadRowTextStyles = css({
        alignItems: "baseline",
        display: "flex",
        gap: Spacing["spacing-2"],
    });

    const downloadRowButtonGroupStyles = css({
        alignItems: "start",
        display: "flex",
        gap: Spacing["spacing-3"],
    });

    const downloadRowDividerStyles = css({
        marginBottom: Spacing["spacing-3"],
        marginTop: Spacing["spacing-3"],
    });

    const secondaryDownloadButtonStyles = css(
        buildStylesByBreakpoint("paddingLeft", {
            extraSmall: Spacing["spacing-3"],
            large: Spacing["spacing-4"],
        }),
        buildStylesByBreakpoint("paddingRight", {
            extraSmall: Spacing["spacing-3"],
            large: Spacing["spacing-4"],
        }),
    );

    const iconWrapperStyles = css({
        display: "flex",
        flexDirection: "column",
        height: Object.values(getIconSize).reduce(
            (acc, size) => (size.height > acc ? size.height : acc), // get the height of the largest icon
            0,
        ),
        justifyContent: "center",
        maxWidth: "fit-content",
        width: getIconSize[_castedOS_Name].width,
    });

    /**
     * Rendering
     */
    const renderPrimaryDownloadButtons = () => {
        const _castedPrimaryActions =
            primaryActions as Array<StrapiDownloadOption>;

        return (
            <div css={primaryButtonContainerStyles}>
                {_castedPrimaryActions.map((action) => {
                    return (
                        <div
                            css={primaryButtonGroupStyles}
                            key={`primary-download-button::${action.id}`}
                        >
                            <Button
                                className={primaryButtonStyles}
                                fontSize="DownloadButtonCTA"
                                href={action.URL}
                                textClassName={primaryButtonTextStyles}
                                track={getDownloadOptionTrack(
                                    action,
                                    props.renderLocation,
                                )}
                                variant="primary"
                            >
                                <Text
                                    color="darken-90"
                                    fontSize="DownloadPrimaryButtonHeading"
                                    textAlign="center"
                                >
                                    {action.Text}
                                </Text>

                                <Text
                                    color="darken-60"
                                    fontSize="DownloadPrimaryButtonSubheading"
                                    textAlign="center"
                                >
                                    {action.Secondary_Text}
                                </Text>
                            </Button>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderPrimaryAction = () => {
        if (
            primaryActions.every(
                (action) => action.__component === "data.data-download-option",
            )
        ) {
            return renderPrimaryDownloadButtons();
        }

        const waitlist = primaryActions.find(
            (action) =>
                action.__component === "components.component-waitlist-form",
        ) as StrapiWaitlistForm | undefined;

        if (!waitlist) {
            return null;
        }

        return (
            <NestedForm
                alignment="start"
                formEndpoint={waitlist.Submission_URL}
                formName="windows waitlist"
                placeholder={waitlist.Placeholder_Text}
                submitText={waitlist.CTA_Text}
                title={waitlist.Title}
            />
        );
    };

    const renderDownloadRows = () => {
        if (!props.Download_Rows || props.Download_Rows.length === 0) {
            return null;
        }

        const divider = <Divider className={downloadRowDividerStyles} />;
        const rowCount = props.Download_Rows.length;

        return (
            <ul>
                {props.Download_Rows.map((row, index) => (
                    <li key={row.id}>
                        <div css={downloadRowStyles}>
                            <div css={downloadRowTextStyles}>
                                <Text
                                    fontSize="DownloadButtonCTA"
                                    themeKey="headlinePrimary"
                                >
                                    {row.Title}
                                </Text>

                                {row.Subtitle && (
                                    <Text
                                        fontSize="TEXT_075"
                                        themeKey="textSecondary"
                                    >
                                        {row.Subtitle}
                                    </Text>
                                )}
                            </div>

                            <div css={downloadRowButtonGroupStyles}>
                                {row.Download_Option.map((option) => (
                                    <Button
                                        className={
                                            secondaryDownloadButtonStyles
                                        }
                                        fontSize="DownloadSecondaryButton"
                                        href={option.URL}
                                        key={option.id}
                                        track={getDownloadOptionTrack(
                                            option,
                                            props.renderLocation,
                                            props.isDownloadPreviewPage,
                                        )}
                                        variant="secondary"
                                    >
                                        {option.Text}
                                    </Button>
                                ))}
                            </div>
                        </div>

                        {index < rowCount - 1 && divider}
                    </li>
                ))}
            </ul>
        );
    };

    const renderDownloadSnippet = () => {
        if (!props.Download_Snippet) {
            return null;
        }

        const code = props.Download_Snippet.Code;

        return (
            <CodeCta
                className={css({ justifyContent: "center" })}
                code={code}
                fontSize="PlanCardDetails"
                track={getCodeCtaTrack(code)}
            />
        );
    };

    return (
        <GridColumn className={downloadCardStyles}>
            <div css={downloadGroupHeaderStyles}>
                <OperatingSystemIcon
                    className={iconWrapperStyles}
                    slug={_castedOS_Name}
                />

                <div css={downloadGroupTextStyles}>
                    <Text fontSize="LongformHeadlineDefault" tag="h2">
                        {props.OS_Name}
                    </Text>

                    {!props.Is_Released && (
                        <Text
                            fontSize="DownloadGroupComingSoonText"
                            themeKey="textSecondary"
                        >
                            Coming soon
                        </Text>
                    )}
                </div>
            </div>

            {renderPrimaryAction()}

            {renderDownloadRows()}

            {renderDownloadSnippet()}
        </GridColumn>
    );
};
