/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { type FunctionComponent } from "react";

import type { StrapiCtaGroup, StrapiOsSpecificCta } from "@/types/strapi";
import type { ElementAlignment } from "@/types/ui";

import { spacingSets } from "@/tokens/configs/spacing_config";
import type { FontStyleSlug } from "@/tokens/configs/typography_config";
import { Spacing } from "@/tokens/spacing";

import { CodeCta } from "@/ui/atoms/code_cta";
import { Link } from "@/ui/atoms/link";
import { DownloadButton } from "@/ui/molecules/download_button";
import { NestedForm } from "@/ui/molecules/nested_form";

import { getCodeCtaTrack } from "@/util/code_cta_util";
import { getDownloadButtonTrack } from "@/util/cta_group_util";
import {
    buildStylesByBreakpoint,
    buildStylesByStringOrObject,
} from "@/util/style_util";
import { RenderLocation } from "@/util/tokens/render_location_util";
import { TrackEvent } from "@/util/tokens/track_event_util";
import { convertToRem } from "@/util/ui_util";

const SEND_DOWNLOAD_ENDPOINT = process.env.NEXT_PUBLIC_SEND_DOWNLOAD_ENDPOINT;

if (SEND_DOWNLOAD_ENDPOINT == null) {
    throw new Error("No SEND_DOWNLOAD_ENDPOINT configured.");
}

interface CtaGroupProps extends StrapiCtaGroup {
    alignment?: ElementAlignment;
    primaryActionFontSize?: FontStyleSlug;
    renderLocation?: RenderLocation;
}

export const CtaGroup: FunctionComponent<CtaGroupProps> = ({
    alignment = "center",
    primaryActionFontSize = "CtaHero",
    renderLocation = RenderLocation.HERO,
    ...props
}) => {
    /**
     * Styles
     */
    const wrapperStyles = css({
        /**
         * We always show download buttons on Windows even on touch devices,
         * e.g. Windows Surface.
         */
        '[data-device-touch]:not([data-device-os="windows"]) &': {
            display: "none",
        },
    });

    const containerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
        },
        buildStylesByStringOrObject("alignItems", alignment),
        buildStylesByBreakpoint("rowGap", spacingSets.CtaGroupRow),
    );

    const primaryActionContainerStyles = css({
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        gap: Spacing["spacing-4"],
    });

    const sendDownloadFormStyles = css(
        {
            /**
             * We always show download buttons on Windows even on touch devices,
             * e.g. Windows Surface.
             */
            '[data-device-touch]:not([data-device-os="windows"]) &': {
                display: "flex",
            },
            display: "none",
        },
        buildStylesByStringOrObject("justifyContent", alignment),
    );

    /**
     * Render
     */
    const renderOsSpecificCta = (cta: StrapiOsSpecificCta) => {
        const osSpecificContainerStyles = css(containerStyles, {
            display: "none",
            [`[data-device-os="${cta.Operating_System}"] &`]: {
                display: "flex",
            },
        });

        return (
            <div css={osSpecificContainerStyles} key={cta.Operating_System}>
                <div css={primaryActionContainerStyles}>
                    {cta.Primary_Action.map((action) => {
                        const fontSize = primaryActionFontSize;
                        switch (action.__component) {
                            case "primitives.primitive-cta-download-button":
                                return (
                                    <DownloadButton
                                        {...action}
                                        fontSize={fontSize}
                                        key={action.id}
                                        track={getDownloadButtonTrack(
                                            cta.Operating_System,
                                            action,
                                            renderLocation,
                                        )}
                                    />
                                );
                            case "primitives.primitive-sales-button":
                                return (
                                    <Link
                                        fontSize={fontSize}
                                        href="/contact-sales"
                                        interactionType="hover-color-change"
                                        key={action.id}
                                        track={TrackEvent.REQUEST_DEMO_LINK}
                                    >
                                        {action.Text}
                                    </Link>
                                );
                        }
                    })}
                </div>

                {cta.Secondary_Action?.map((action) => (
                    <CodeCta
                        code={action.Code}
                        fontSize="Micro"
                        key={action.id}
                        track={getCodeCtaTrack(action.Code)}
                    />
                ))}
            </div>
        );
    };

    return (
        <>
            <div css={wrapperStyles}>
                {props.By_OS?.map(renderOsSpecificCta)}
            </div>

            <div css={sendDownloadFormStyles}>
                <NestedForm
                    alignment={alignment}
                    className={css({ maxWidth: convertToRem(460) })}
                    formEndpoint={SEND_DOWNLOAD_ENDPOINT}
                    formName="Mobile Download Form"
                    placeholder="Enter your email"
                    submitText="Send link"
                    title="On a mobile device? Send Warp to your work station."
                />
            </div>
        </>
    );
};
