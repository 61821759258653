import type { AnalyticsTrack } from "@/types/analytics";
import type { StrapiCtaDownloadButton } from "@/types/strapi";

import type { RenderLocation } from "@/util/tokens/render_location_util";
import { getTrackEvent } from "@/util/tokens/track_event_util";

type Properties = {
    arch: StrapiCtaDownloadButton["Architecture"];
    download_package: StrapiCtaDownloadButton["Package"];
    location?: RenderLocation;
    os_category: "Linux" | "MacOS" | "Windows";
};

export function getDownloadButtonTrack(
    operatingSystem: "mac" | "linux" | "windows" | undefined,
    action: StrapiCtaDownloadButton,
    location?: RenderLocation,
): AnalyticsTrack | undefined {
    if (!operatingSystem) {
        return;
    }

    let os_category: Properties["os_category"];
    switch (operatingSystem) {
        case "mac":
            os_category = "MacOS";
            break;
        case "linux":
            os_category = "Linux";
            break;
        case "windows":
            os_category = "Windows";
            break;
        default: {
            const os: never = operatingSystem;
            throw new Error(`Unhandled operating system: ${os}.`);
        }
    }

    const properties: Properties = {
        arch: action.Architecture,
        download_package: action.Package,
        location,
        os_category,
    };

    if (location) {
        properties.location = location;
    }

    return getTrackEvent("DOWNLOAD_BUTTON_CLICKED", properties);
}
