import { Color, Colors } from "@/tokens/color";

export type ColorValue = keyof typeof Colors | string;
type RGBAComponents = { a: number; b: number; g: number; r: number };

/**
 * Converts a color (hex, rgb, rgba, or Colors enum) to its RGBA components
 * Returns null if the color format is invalid
 */
export function parseColorToRGBA(color: ColorValue): RGBAComponents | null {
    try {
        // Handle undefined/null
        if (!color) return null;

        // Convert Colors enum to actual color value
        const colorValue =
            typeof color === "string" && color in Colors
                ? Colors[color as Color]
                : color;

        // Handle hex colors
        if (colorValue.startsWith("#")) {
            const hex = colorValue.slice(1);
            const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
            return { a: 1, b, g, r };
        }

        // Handle rgb/rgba colors
        const rgbaMatch = colorValue.match(
            /rgba?\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)(?:\s*,\s*([\d.]+))?\)/,
        );

        if (rgbaMatch) {
            const [, r, g, b, a = "1"] = rgbaMatch;

            return {
                a: parseFloat(a),
                b: parseInt(b, 10),
                g: parseInt(g, 10),
                r: parseInt(r, 10),
            };
        }

        return null;
    } catch (error) {
        console.warn("Color parsing failed:", error);
        return null;
    }
}

/**
 * Formats RGBA components to a color string
 */
export function formatColor(
    components: RGBAComponents,
    format: "rgb" | "rgba" | "hex" = "rgb",
): string {
    const { a, b, g, r } = components;

    switch (format) {
        case "rgba":
            return `rgba(${r}, ${g}, ${b}, ${a})`;
        case "hex": {
            const toHex = (n: number) => n.toString(16).padStart(2, "0");
            return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
        }
        default:
            return `rgb(${r}, ${g}, ${b})`;
    }
}

/**
 * Blends a transparent color with a background color
 * Returns a solid color that looks visually identical
 */
export const flattenTransparentColor = (
    color: ColorValue,
    backgroundColor: ColorValue = "#FFFFFF",
): string => {
    try {
        const fg = parseColorToRGBA(color);
        const bg = parseColorToRGBA(backgroundColor);

        if (!fg || !bg || fg.a === 1) {
            return typeof color === "string" && color in Colors
                ? Colors[color as Color]
                : color;
        }

        const blend = (fg: number, bg: number, alpha: number) =>
            Math.round(fg * alpha + bg * (1 - alpha));

        return formatColor({
            a: 1,
            b: blend(fg.b, bg.b, fg.a),
            g: blend(fg.g, bg.g, fg.a),
            r: blend(fg.r, bg.r, fg.a),
        });
    } catch (error) {
        console.warn("Color flattening failed:", error);
        return typeof color === "string" && color in Colors
            ? Colors[color as Color]
            : color;
    }
};

export function hex2rgba(hex: Color | string, alpha = 1): string {
    const components = parseColorToRGBA(hex);
    if (!components) return Colors.transparent;
    return formatColor({ ...components, a: alpha }, "rgba");
}

/**
 * Gradient Generator
 */
export function generateLinearGradient(start: string, end: string) {
    return `linear-gradient(95deg, ${start} 0%, ${end} 100%)`;
}

export function generateRadialGradient(
    steps: Array<string>,
    backgroundColor?: string,
    centerPointPercent: {
        horizontal: number;
        vertical: number;
    } = {
        horizontal: 50,
        vertical: 115,
    },
) {
    const horizontalOffset = `${centerPointPercent.horizontal}%`;
    const verticalOffset = `${centerPointPercent.vertical}%`;

    const stepsAsString = steps.join(", ");

    const backgroundString = backgroundColor
        ? `, ${backgroundColor}`
        : undefined;

    return `radial-gradient(circle at ${horizontalOffset} ${verticalOffset}, ${stepsAsString})${backgroundString}`;
}

/**
 * Box Shadow Generator
 */
export function generateBoxShadow(
    color: string,
    x: number = 0,
    y: number = 0,
    blur: number = 0,
    spread: number = 8,
) {
    return `${x}px ${y}px ${blur}px ${spread}px ${color}`;
}

/**
 * Color Interpolation
 */
export function colorInterpolate(
    colorA: string,
    colorB: string,
    intval: number,
): string {
    const compA = parseColorToRGBA(colorA);
    const compB = parseColorToRGBA(colorB);

    if (!compA || !compB) return Colors.transparent;

    const colorVal = (
        prop: keyof Pick<RGBAComponents, "r" | "g" | "b">,
    ): number => Math.round(compA[prop] * (1 - intval) + compB[prop] * intval);

    return formatColor({
        a: 1,
        b: colorVal("b"),
        g: colorVal("g"),
        r: colorVal("r"),
    });
}

/**
 * Interpolates between infinite color stops on a linear gradient
 * Color stops are in the format `[rgb(1.0, 1.0, 1.0), offset]`
 * Where `rgb...` is the color, and `offset` represents the position
 * of the color stop between 0 and 1. See `addColorStop` for inspiration:
 * https://developer.mozilla.org/en-US/docs/Web/API/CanvasGradient/addColorStop
 * `intval` is between 0 and 1 and determines the point along the gradient to sample
 */

export function colorInterpolateStops(
    stops: [string, number][],
    intval: number,
): string {
    const points = stops
        .sort((a, b) => a[1] - b[1])
        .filter((stop, _index) => {
            if (stop[1] == intval) {
                return true;
            } else if (_index == 0) {
                return stop[1] < intval && stops[_index + 1][1] > intval;
            } else if (_index == stops.length - 1) {
                return stop[1] > intval && stops[_index - 1][1] < intval;
            }
            return (
                (stop[1] > intval && stops[_index - 1][1] < intval) ||
                (stop[1] < intval && stops[_index + 1][1] > intval)
            );
        });

    if (points.length == 1) {
        return colorInterpolate(points[0][0], points[0][0], 0.5);
    }

    const scaledIntval =
        (intval - points[0][1]) / (points[1][1] - points[0][1]);

    return colorInterpolate(points[0][0], points[1][0], scaledIntval);
}

/**
 * Returns a contrasting color to ensure that foreground and background color
 * combinations provide sufficient contrast when viewed by someone having
 * color deficits or when viewed on a black and white screen.
 * Based on formula from W3C here: http://www.w3.org/TR/AERT#color-contrast
 */

export function setContrast(
    baseColor: string,
    lightColor: string,
    darkColor: string,
): string {
    const components = parseColorToRGBA(baseColor);
    if (!components) return lightColor;

    // http://www.w3.org/TR/AERT#color-contrast
    const brightness = Math.round(
        (components.r * 299 + components.g * 587 + components.b * 114) / 1000,
    );
    return brightness > 140 ? darkColor : lightColor;
}
