/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiPreFooter } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { BoxShadows, Colors } from "@/tokens/color";
import { spacingSets } from "@/tokens/configs/spacing_config";
import { ColumnGaps, HalfColumnGaps, Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Icon } from "@/ui/atoms/icon";
import { Link } from "@/ui/atoms/link";
import { Picture } from "@/ui/atoms/picture";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { CtaGroup } from "@/ui/molecules/cta_group";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import {
    buildObjectStylesByBreakpoint,
    buildStylesByBreakpoint,
    hideOnExtraSmall,
    showOnExtraSmall,
} from "@/util/style_util";
import { backgroundBlur } from "@/util/style_util";
import { RenderLocation } from "@/util/tokens/render_location_util";

interface PreFooterProps extends Omit<StrapiPreFooter, "Background_Media"> {
    Background_Media?: StrapiPreFooter["Background_Media"];
    className?: SerializedStyles;
}

export const PreFooter: FunctionComponent<PreFooterProps> = (props) => {
    /**
     * Styles
     */
    const theme = useTypedTheme();
    const contentZIndex = 5;

    const preFooterContainerStyle = css(
        {
            alignItems: "start",
            borderRadius: BorderRadiuses.borderLarge,
            gridColumnGap: ColumnGaps.medium,
            gridTemplateColumns: "178px auto min-content", // 178px is equal to the width of the third column (sidebar)
            position: "relative",
        },
        buildStylesByBreakpoint("paddingLeft", HalfColumnGaps),
        buildStylesByBreakpoint("paddingRight", HalfColumnGaps),
        buildStylesByBreakpoint("paddingBottom", HalfColumnGaps),
        buildObjectStylesByBreakpoint({
            extraSmall: {
                gridTemplateAreas: '"content content content"',
                paddingTop: Spacing["spacing-3"],
                textAlign: "left",
            },
            medium: {
                gridTemplateAreas: '". content sidebar"',
                paddingBottom: Spacing["spacing-2"],
                paddingLeft: Spacing["spacing-2"],
                paddingRight: Spacing["spacing-2"],
                paddingTop: Spacing["spacing-2"],
                textAlign: "center",
            },
        }),
        props.className,
    );

    const backgroundMediaStyles = css({
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
    });

    const contentContainerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
            gridArea: "content",
            zIndex: contentZIndex,
        },
        buildStylesByBreakpoint("alignItems", {
            extraSmall: "start",
            medium: "center",
        }),
        buildStylesByBreakpoint("paddingTop", {
            extraSmall: Spacing["spacing-0"],
            small: Spacing["spacing-2"],
            medium: Spacing["spacing-16"],
            large: Spacing["spacing-18"],
        }),
        buildStylesByBreakpoint("paddingBottom", {
            extraSmall: Spacing["spacing-8"],
            small: Spacing["spacing-10"],
            medium: Spacing["spacing-16"],
            large: Spacing["spacing-18"],
        }),
        buildStylesByBreakpoint("paddingLeft", HalfColumnGaps),
        buildStylesByBreakpoint("paddingRight", HalfColumnGaps),
    );

    const sidebarStyles = css(
        {
            backdropFilter: backgroundBlur("blurMedium"),
            background: Colors[theme.backgrounds.backgroundTertiary],
            borderRadius: BorderRadiuses.borderMedium,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: Spacing["spacing-2"],
            textAlign: "left",
            zIndex: contentZIndex,
        },
        buildObjectStylesByBreakpoint({
            medium: {
                gridArea: "sidebar",
                height: "100%",
            },
        }),
        hideOnExtraSmall(),
    );

    /**
     * Rendering
     */
    const renderEyebrow = () => {
        return (
            <Text
                shouldBalanceWrap
                fontSize="Headline1"
                tag="h2"
                themeKey="headlineSecondary"
            >
                {props.Eyebrow}
            </Text>
        );
    };

    const renderHeadline = () => {
        return (
            <Text
                shouldBalanceWrap
                className={css(
                    buildObjectStylesByBreakpoint({
                        large: {
                            maxWidth: "25ch", // 2 line break
                            textWrap: "pretty",
                        },
                        extraLarge: {
                            maxWidth: "27ch", // 2 line break
                        },
                    }),
                )}
                fontSize="Headline1"
                tag="p"
                themeKey="headlinePrimary"
            >
                {props.Headline}
            </Text>
        );
    };

    const renderCtaGroup = () =>
        props.CTA_Group && (
            <div
                css={buildStylesByBreakpoint(
                    "marginTop",
                    spacingSets.TextHeaderCta,
                )}
            >
                <CtaGroup
                    {...props.CTA_Group}
                    alignment={{
                        extraSmall: "start",
                        medium: "center",
                    }}
                    primaryActionFontSize="CtaDefault"
                    renderLocation={RenderLocation.PRE_FOOTER}
                />
            </div>
        );

    const renderSidebar = () => {
        const { Text: LinkText, URL } = props.Next_Link;
        return (
            <>
                <GridColumn
                    className={css(
                        {
                            backdropFilter: backgroundBlur("blurMedium"),
                            backgroundColor:
                                Colors[theme.backgrounds.backgroundTertiary],
                            borderRadius: BorderRadiuses.borderMedium,
                            boxShadow: BoxShadows["box-shadow-transparent"],
                            zIndex: contentZIndex,
                        },
                        showOnExtraSmall("grid"),
                    )}
                    columnSpan="full"
                >
                    <Link
                        className={css({
                            alignItems: "center",
                            display: "flex",
                            gap: Spacing["spacing-1"],
                            justifyContent: "space-between",
                            padding: Spacing["spacing-1"],
                            zIndex: contentZIndex,
                        })}
                        href={URL}
                        interactionType="hover-color-change"
                    >
                        <span css={css({ padding: Spacing["spacing-1"] })}>
                            <Text
                                fontSize="Headline3"
                                themeKey="headlineSecondary"
                            >
                                Next up
                            </Text>

                            <Text
                                fontSize="Headline3"
                                themeKey="headlinePrimary"
                            >
                                {LinkText}
                            </Text>
                        </span>

                        <Icon
                            className={css({
                                marginRight: Spacing["spacing-1"],
                            })}
                            color={theme.buttons.buttonSecondary.icon.default}
                            size={20}
                            slug="Arrow Right"
                        />
                    </Link>
                </GridColumn>

                <aside css={sidebarStyles}>
                    <div css={css({ padding: Spacing["spacing-1"] })}>
                        <Text fontSize="Headline3" themeKey="textSecondary">
                            Next up
                        </Text>

                        <Text fontSize="Headline3" themeKey="textPrimary">
                            {LinkText}
                        </Text>
                    </div>

                    <Button fontSize="TextSmall" href={URL} variant="secondary">
                        Explore {LinkText}
                    </Button>
                </aside>
            </>
        );
    };

    return (
        <GridContainer legacyGrid={false}>
            <SubgridContainer className={preFooterContainerStyle}>
                {props.Background_Media && (
                    <Picture
                        {...props.Background_Media}
                        aspectRatio="stretch"
                        className={backgroundMediaStyles}
                        shouldParallax={true}
                        sizeConfiguration="fullWidth"
                    />
                )}

                <GridColumn
                    className={contentContainerStyles}
                    columnSpan="full"
                >
                    {renderEyebrow()}

                    {renderHeadline()}

                    {renderCtaGroup()}
                </GridColumn>

                {renderSidebar()}
            </SubgridContainer>
        </GridContainer>
    );
};
