/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import { AnalyticsTrack } from "@/types/analytics";
import type { StrapiCtaDownloadButton } from "@/types/strapi";

import type { FontStyleSlug } from "@/tokens/configs/typography_config";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { Text } from "@/ui/atoms/text";

interface DownloadButtonProps extends StrapiCtaDownloadButton {
    fontSize: FontStyleSlug;
    track?: AnalyticsTrack;
}

export const DownloadButton: FunctionComponent<DownloadButtonProps> = (
    props,
) => {
    const containerStyles = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: Spacing["spacing-2"],
    });

    return (
        <div css={containerStyles}>
            <Button
                fontSize={props.fontSize}
                href={props.URL}
                iconPlacement="action"
                iconSlug="Download"
                newWindow={true}
                track={props.track}
            >
                {props.Text}
            </Button>

            {props.Secondary_Text && (
                <Text
                    fontSize="Micro"
                    textAlign="center"
                    themeKey="textSecondary"
                >
                    {props.Secondary_Text}
                </Text>
            )}
        </div>
    );
};
