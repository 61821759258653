import type { AnalyticsTrack } from "@/types/analytics";

import { TrackEvent } from "@/util/tokens/track_event_util";

export function getCodeCtaTrack(code: string): AnalyticsTrack | undefined {
    if (code.startsWith("brew install")) {
        return TrackEvent.BREW_INSTALL_COMMAND;
    }
    if (code.startsWith("winget install")) {
        return TrackEvent.WINGET_INSTALL_COMMAND;
    }
    return undefined;
}
