/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { Check, Copy, CurrencyDollar } from "@phosphor-icons/react";
import { FunctionComponent, useEffect, useRef, useState } from "react";

import { AnalyticsTrack } from "@/types/analytics";
import { TextThemeKeys } from "@/types/tokens/themes";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { FontFamily } from "@/tokens/fonts";
import { Spacing } from "@/tokens/spacing";

import { useCodeCtaIconSwapAnimation } from "@/util/animation_hooks/button_animations";
import { useCopyToClipboard } from "@/util/hooks/clipboard_hooks";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { useRudderAnalyticsTrack } from "@/util/hooks/use_rudder_analytics";
import { TextColor } from "@/util/tokens/typography_util";
import { convertToRem } from "@/util/ui_util";

import { Text } from "./text";

export type CodeCtaInteractiveState =
    | "default"
    | "hover"
    | "active"
    | "complete";

interface CodeCtaProps {
    className?: SerializedStyles;
    code: string;
    color?: TextColor;
    fontFamily?: FontFamily;
    fontSize?: FontStyleSlug;
    themeKey?: TextThemeKeys;
    track?: AnalyticsTrack;
}

export const CodeCta: FunctionComponent<CodeCtaProps> = ({
    fontFamily = "mono",
    fontSize = "TextSmall",
    themeKey = "textPrimary",
    ...props
}) => {
    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * Refs
     */
    const defaultIconContainerRef = useRef(null);
    const activeIconContainerRef = useRef(null);
    const successIconContainerRef = useRef(null);

    /**
     * State Management
     */
    const [interactiveState, setInteractiveState] =
        useState<CodeCtaInteractiveState>("default");

    const copyStatus = useCopyToClipboard(
        props.code,
        interactiveState === "active",
    );

    /**
     * Animation
     */
    useCodeCtaIconSwapAnimation(
        defaultIconContainerRef,
        activeIconContainerRef,
        successIconContainerRef,
        interactiveState,
    );

    /**
     * Effects
     */
    useEffect(() => {
        if (copyStatus === "success" && interactiveState === "active") {
            const timer = setTimeout(() => {
                setInteractiveState("complete");
            }, 150); // Fake delay to show "press" animation

            return () => clearTimeout(timer);
        }
    }, [copyStatus, interactiveState]);

    /**
     * Interactivity
     */

    const handleMouseEnter = () => {
        setInteractiveState("hover");
    };

    const handleMouseLeave = () => {
        if (["hover", "complete"].includes(interactiveState)) {
            setInteractiveState("default");
        }
    };

    const track = useRudderAnalyticsTrack();

    const handleCopy = () => {
        if (props.track) {
            track(props.track);
        }
    };

    const handleMouseDown = () => {
        if (props.track) {
            track(props.track);
        }
        setInteractiveState("active");
    };

    /**
     * Styles
     */
    const containerStyles = css(
        {
            alignItems: "center",
            columnGap: Spacing["spacing-2"], // Hard-coded because it's the same across all breakpoints
            display: "flex",
        },
        props.className,
    );

    const buttonStyles = () => {
        return css({
            alignItems: "center",
            appearance: "none",
            background: "transparent",
            border: "none",
            borderRadius: BorderRadiuses.borderRound,
            cursor: "pointer",
            display: "flex",
            height: convertToRem(40),
            justifyContent: "center",
            position: "relative",
            width: convertToRem(40),
        });
    };

    const iconContainerStyles = css({
        alignItems: "center",
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
    });

    const activeIconContainerStyles = css(iconContainerStyles, {
        visibility: "hidden",
    });

    const baseIconStyles = css({
        alignItems: "center",
        borderRadius: BorderRadiuses.borderRound,
        display: "flex",
        flexGrow: 0,
        flexShrink: 0,
        justifyContent: "center",
    });

    const defaultIconStyles = css(baseIconStyles, {
        background: Colors[theme.backgrounds.backgroundSecondary],
        height: convertToRem(32),
        width: convertToRem(32),
    });

    const activeIconStyles = css(baseIconStyles, {
        background: Colors[theme.backgrounds.backgroundInverted],
        height: convertToRem(40),
        width: convertToRem(40),
    });

    /**
     * Rendering
     */
    const renderDefaultIcon = () => {
        return (
            <div css={iconContainerStyles} ref={defaultIconContainerRef}>
                <div css={defaultIconStyles}>
                    <CurrencyDollar
                        color={Colors[theme.ui.uiActive]}
                        size={convertToRem(14)}
                    />
                </div>
            </div>
        );
    };

    const renderActiveIcon = () => {
        return (
            <div css={activeIconContainerStyles} ref={activeIconContainerRef}>
                <div css={activeIconStyles}>
                    <Copy
                        color={Colors[theme.ui.uiActiveInverted]}
                        size={convertToRem(18)}
                    />
                </div>
            </div>
        );
    };

    const renderSuccessIcon = () => {
        return (
            <div css={activeIconContainerStyles} ref={successIconContainerRef}>
                <div css={activeIconStyles}>
                    <Check
                        color={Colors[theme.ui.uiActiveInverted]}
                        size={convertToRem(18)}
                    />
                </div>
            </div>
        );
    };

    const renderIcon = () => {
        return (
            <button
                aria-label="Copy to clipboard"
                css={buttonStyles}
                onMouseDown={handleMouseDown}
            >
                {renderDefaultIcon()}

                {renderActiveIcon()}

                {renderSuccessIcon()}
            </button>
        );
    };

    return (
        <div
            css={containerStyles}
            onCopy={handleCopy}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {renderIcon()}

            <Text
                color={props.color}
                fontFamily={fontFamily}
                fontSize={fontSize}
                tag="code"
                themeKey={themeKey}
            >
                {props.code}
            </Text>
        </div>
    );
};
