/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import type { FunctionComponent, MouseEventHandler } from "react";

import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";

import { TrackEvent } from "@/util/tokens/track_event_util";

interface DownloadButtonProps {
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

export const DownloadButton: FunctionComponent<DownloadButtonProps> = (
    props,
) => {
    /**
     * Styles
     */
    const containerStyles = css({
        alignItems: "center",
        columnGap: Spacing["spacing-2"],
        display: "flex",
    });

    /**
     * Show direct download on Mac. On other OS, send users to /download instead.
     */
    const macDownloadStyles = css({
        'html:not([data-device-os="mac"]) &': {
            display: "none",
        },
    });

    const otherOsDownloadStyles = css({
        '[data-device-os="mac"] &': {
            display: "none",
        },
    });

    return (
        <div css={containerStyles}>
            <Button
                className={macDownloadStyles}
                fontSize="NavText"
                href="https://app.warp.dev/get_warp?package=dmg"
                newWindow={true}
                track={TrackEvent.NAV_BAR_DOWNLOAD}
                onClick={props.onClick}
            >
                Download for Mac
            </Button>

            <Button
                className={otherOsDownloadStyles}
                fontSize="NavText"
                href="/download"
                track={TrackEvent.ALL_DOWNLOADS_LINK}
                onClick={props.onClick}
            >
                Download Warp
            </Button>
        </div>
    );
};
