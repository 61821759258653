import type { AnalyticsTrack } from "@/types/analytics";
import type { StrapiDownloadOption } from "@/types/strapi";

import type { RenderLocation } from "@/util/tokens/render_location_util";
import { getTrackEvent } from "@/util/tokens/track_event_util";

type Properties = {
    arch: StrapiDownloadOption["Architecture"];
    download_package: StrapiDownloadOption["Package"];
    location?: RenderLocation;
    os_category: "Linux" | "MacOS" | "Windows";
    preview?: "true";
};

export function getDownloadOptionTrack(
    option: StrapiDownloadOption,
    location?: RenderLocation,
    preview?: true,
): AnalyticsTrack {
    let os_category: Properties["os_category"];
    switch (option.Operating_System) {
        case "mac":
            os_category = "MacOS";
            break;
        case "linux":
            os_category = "Linux";
            break;
        case "windows":
            os_category = "Windows";
            break;
        default: {
            const os: never = option.Operating_System;
            throw new Error(`Unhandled operating system: ${os}.`);
        }
    }

    const properties: Properties = {
        arch: option.Architecture,
        download_package: option.Package,
        location,
        os_category,
    };

    if (location) {
        properties.location = location;
    }

    if (preview) {
        properties.preview = "true";
    }

    return getTrackEvent("DOWNLOAD_BUTTON_CLICKED", properties);
}
