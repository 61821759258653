import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { useState } from "react";

import { TypedRefObject } from "@/types/interactivity";
import { ActivityThemeState, ButtonTheme } from "@/types/tokens/themes";

import { Colors } from "@/tokens/color";

import { CodeCtaInteractiveState } from "@/ui/atoms/code_cta";

export interface ButtonInteractiveState {
    isActive: boolean;
    isFocused: boolean;
    isHovered: boolean;
}

export function useButtonHover(
    buttonContainerRef: TypedRefObject,
    iconContainerRef: TypedRefObject,
    buttonTheme: ButtonTheme,
    interactiveState: ButtonInteractiveState,
    renderIconContainer: boolean,
) {
    useGSAP(() => {
        gsap.set(buttonContainerRef.current, {
            background: Colors[buttonTheme.background.default],
            boxShadow: Colors[buttonTheme.boxShadow.default],
        });
    }, [
        buttonContainerRef,
        buttonTheme.background.default,
        buttonTheme.boxShadow.default,
    ]);

    useGSAP(() => {
        const _getCurrentState = (): ActivityThemeState => {
            const { isActive, isFocused, isHovered } = interactiveState;

            if (isFocused) {
                return "focus";
            } else if (isActive) {
                return "active";
            } else if (isHovered) {
                return "hover";
            } else {
                return "default";
            }
        };

        const currentState = _getCurrentState();

        gsap.to(buttonContainerRef.current, {
            background: Colors[buttonTheme.background[currentState]],
            boxShadow: Colors[buttonTheme.boxShadow[currentState]],
            color: Colors[buttonTheme.text[currentState]],
        });

        if (iconContainerRef.current) {
            gsap.to(iconContainerRef.current, {
                background: Colors[buttonTheme.iconContainer[currentState]],
                color: Colors[buttonTheme.icon[currentState]],
            });
        }
    }, [
        interactiveState,
        buttonContainerRef,
        buttonTheme.background,
        buttonTheme.boxShadow,
        buttonTheme.icon,
        buttonTheme.iconContainer,
        buttonTheme.text,
        iconContainerRef,
        renderIconContainer,
    ]);
}

/**
 * Code Snippet Icon Swap
 */
export function useCodeCtaIconSwapAnimation(
    defaultIconContainerRef: TypedRefObject,
    activeIconContainerRef: TypedRefObject,
    successIconContainerRef: TypedRefObject,
    interactiveState: CodeCtaInteractiveState,
) {
    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useGSAP(() => {
        gsap.set(defaultIconContainerRef.current, {
            autoAlpha: 1,
            scale: 1,
        });

        gsap.set(
            [activeIconContainerRef.current, successIconContainerRef.current],
            {
                autoAlpha: 0,
                onComplete: () => {
                    setDidInit(true);
                },
                scale: 0.75,
            },
        );
    }, []);

    useGSAP(() => {
        const _elasticEasing = "elastic.out(2, 0.4)";

        const _animateOutConfig: gsap.TweenVars = {
            autoAlpha: 0,
            duration: 0.15,
            scale: 0.75,
        };

        const _animateAutoAlphaInConfig: gsap.TweenVars = {
            autoAlpha: 1,
            duration: 0.25,
        };

        const _animateScaleInConfig: gsap.TweenVars = {
            duration: 0.65,
            ease: _elasticEasing,
            scale: 1,
        };

        if (didInit) {
            if (interactiveState === "hover") {
                const _timeline = gsap.timeline({ paused: true });

                _timeline.to(
                    defaultIconContainerRef.current,
                    _animateOutConfig,
                );

                _timeline.to(
                    activeIconContainerRef.current,
                    _animateAutoAlphaInConfig,
                );

                _timeline.to(
                    activeIconContainerRef.current,
                    _animateScaleInConfig,
                    "<",
                );

                _timeline.play();

                return () => {
                    _timeline.kill();
                };
            } else if (interactiveState === "active") {
                const _animation = gsap.to(activeIconContainerRef.current, {
                    duration: 0.65,
                    ease: _elasticEasing,
                    scale: 0.85,
                });

                return () => {
                    _animation.kill();
                };
            } else if (interactiveState === "complete") {
                const _timeline = gsap.timeline({ paused: true });

                _timeline.to(activeIconContainerRef.current, {
                    autoAlpha: 0,
                    duration: 0.15,
                    scale: 1,
                });

                _timeline.to(
                    successIconContainerRef.current,
                    _animateAutoAlphaInConfig,
                );

                _timeline.to(
                    successIconContainerRef.current,
                    _animateScaleInConfig,
                    "<",
                );

                _timeline.play();

                return () => {
                    _timeline.kill();
                };
            } else if (interactiveState === "default") {
                const _timeline = gsap.timeline({ paused: true });

                _timeline.to(
                    [
                        activeIconContainerRef.current,
                        successIconContainerRef.current,
                    ],
                    _animateOutConfig,
                );

                _timeline.to(
                    defaultIconContainerRef.current,
                    _animateAutoAlphaInConfig,
                );

                _timeline.to(
                    defaultIconContainerRef.current,
                    _animateScaleInConfig,
                    "<",
                );

                _timeline.play();

                return () => {
                    _timeline.kill();
                };
            }
        }
    }, [
        interactiveState,
        activeIconContainerRef,
        defaultIconContainerRef,
        didInit,
        successIconContainerRef,
    ]);
}
